<template>
  <div class="screen-product-page">
    <div class="header">
      <span class="title">单银幕产出</span>
      <div class="update-time">最后更新：{{ updateTime }}</div>
    </div>

    <div class="data-table">
      <div class="table-header">
        <div class="col month">月份</div>
        <div class="col amount">单银幕产出</div>
        <div class="col amount">本地平均单银幕产出</div>
      </div>

      <div class="table-body">
        <div 
          v-for="(item, index) in dataList" 
          :key="index"
          class="table-row"
          :class="{ 'row-even': index % 2 === 1 }"
        >
          <div class="col month">{{ item.mouth }}</div>
          <div class="col amount">{{ item.Produce }}元</div>
          <div class="col amount">{{ item.localProduce }}元</div>
        </div>

        <div class="table-row total-row">
          <div class="col month">总平均</div>
          <div class="col amount highlight">{{ AvgScreenValue }}元</div>
          <div class="col amount">{{ CityAvgScreenValue }}元</div>
        </div>
      </div>
    </div>

    <div class="description">
      <van-icon name="info-o" />
      <div class="desc-text">
        页面展示1月-本月平均每个月单银幕产出值和本地城市平均银幕产出值，总平均展示本影城单银幕产出和本地城市平均银幕产出
      </div>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'ScreenProduct',
  data() {
    return {
      dataList: [],
      AvgScreenValue: 0,
      CityAvgScreenValue: 0,
      updateTime: '2018-01-01',
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101603","cmdArgs":["${this.cinemaId}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        const { Success, Message, data } = r
        if (!Success) {
          Toast(Message)
        } else {
          this.updateTime = data.UpdateDate
          this.AvgScreenValue = data.AvgScreenValue
          this.CityAvgScreenValue = data.CityAvgScreenValue
          this.dataList = data.monthDatas.map((item) => ({
            mouth: `${item.DateMonth}月`,
            Produce: item.AvgScreenValue,
            localProduce: item.CityAvgScreenValue,
          }))
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.screen-product-page {
  min-height: 100vh;
  background: #f5f7fa;
  padding: 16px;
}

.header {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  .update-time {
    font-size: 13px;
    opacity: 0.8;
  }
}

.data-table {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  .table-header {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    background: #f8f9fa;
    padding: 16px 12px;
    font-weight: 500;
    color: #666;
    font-size: 14px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .table-body {
    .table-row {
      display: grid;
      grid-template-columns: 1fr 2fr 2fr;
      padding: 16px 12px;
      transition: background-color 0.3s ease;
      align-items: center;
      
      &.row-even {
        background: #f8f9fa;
      }

      &:hover {
        background: #f0f7ff;
      }

      &.total-row {
        background: #e6f7ff;
        font-weight: 500;
      }
    }
  }

  .col {
    text-align: center;
    font-size: 14px;
    color: #333;

    &.month {
      color: #666;
    }

    &.amount {
      color: #1989fa;
      
      &.highlight {
        color: #f5222d;
        font-weight: 600;
      }
    }
  }
}

.description {
  margin-top: 16px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  
  .van-icon {
    font-size: 16px;
    color: #999;
    margin-top: 2px;
  }

  .desc-text {
    color: #999;
    font-size: 13px;
    line-height: 1.5;
  }
}
</style>
